<template>
  <div>
    <div v-if="!loader">

      <b-row>
        <b-col
          v-for="(item , index) in bankAccountsData"
          :key="index"
          cols="12"
        >
          <div class="my-1">

            <b-button
              :id="`toggle-${index}`"
              v-b-toggle="`accordion-${index + 1}`"
              :block="true"
              :style="{ background: `${gradientColors[index] !== undefined ? gradientColors[index] : ''}`, border: 'none', color: 'white' , fontSize: '13px' }"
              @click="toggleIcon(index)"
            >
              {{ item.office_name }} ({{ item.bank_accounts.currency }})
              <feather-icon
                :icon="activeAccordion === index ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                class="float-right"
                size="16"
              />
            </b-button>
            <b-collapse
              :id="`accordion-${index + 1}`"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text><strong> Bank Balance: </strong> {{ item.bank_accounts.totals.bank_balance }} </b-card-text>
                <b-card-text><strong> Card Balance: </strong> {{ item.bank_accounts.totals.card_balance }} </b-card-text>
                <b-card-text><strong> Cash In Hand: </strong> {{ item.bank_accounts.totals.cash_in_hand }} </b-card-text>
                <b-card-text><strong> Total Balance: </strong> {{ item.bank_accounts.totals.total_balance }} </b-card-text>
                <b-card-text class="text-right">
                  <b-button
                    v-b-toggle.collapse-1-inner
                    size="sm"
                  >
                    Show accounts details
                  </b-button>
                  <b-collapse
                    id="collapse-1-inner"
                    class="mt-2"
                  >
                    <b-card class="text-left">
                      <span v-if="item.bank_accounts.accounts.length > 0">
                        <b-table
                          small
                          :fields="fields"
                          :items="item.bank_accounts.accounts"
                          responsive="sm"
                          bordered
                          class="table-banks-accounts"
                          head-variant="dark"
                        />
                      </span>
                      <span v-else>
                        no data available in this office
                      </span>

                    </b-card>
                  </b-collapse>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </div>

        </b-col>
      </b-row>
    </div>
    <div
      v-else
      class="text-center pt-5"
    >
      <h3
        class="text-primary"
        style="font-weight: bold; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;"
      >
        Loading . . .
      </h3>
      <b-spinner
        variant="primary"
        style="width: 3rem; height: 3rem; margin-top: 2rem;"
        label="Large Spinner"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import decryptData from '@/store/auth/decryptData'

export default {
  data() {
    return {
      loader: false,
      bankAccountsData: [],
      activeAccordion: null, // Track active accordion index
      gradientColors: [
        'linear-gradient( 135deg, #ff7e5f 0%, #feb47b 40%, #feb47b 60%, #ff7e5f 100%)',
        'linear-gradient( 135deg, #6a11cb 0%, #2575fc 40%, #2575fc 60%, #6a11cb 100%)',
        'linear-gradient( 135deg, #11998e 0%, #38ef7d 40%, #38ef7d 60%, #11998e 100%)',
        'linear-gradient( 135deg, #ff0000 0%, #ff6666 40%, #ff6666 60%, #ff0000 100%)',
        'linear-gradient( 135deg, #fbc02d 0%, #ffd54f 40%, #ffd54f 60%, #fbc02d 100%)',
        'linear-gradient( 135deg, #616161 0%, #bdbdbd 40%, #bdbdbd 60%, #616161 100%)',
        'linear-gradient( 135deg, #1976d2 0%, #64b5f6 40%, #64b5f6 60%, #1976d2 100%)',
        'linear-gradient( 135deg, #8e44ad 0%, #c466ea 40%, #c466ea 60%, #8e44ad 100%)',
        'linear-gradient( 135deg, #e67e22 0%, #ffd491 40%, #ffd491 60%, #e67e22 100%)',
        'linear-gradient( 135deg, #2c3e50 0%, #737577 40%, #737577 60%, #2c3e50 100%)',
        'linear-gradient( 135deg, #1abc9c 0%, #16a085 40%, #16a085 60%, #1abc9c 100%)',
      ],
      fields: [
        { key: 'account_name', label: 'Account Name' },
        { key: 'running_balance', label: 'Running Balance' },
        { key: 'account_type', label: 'Account Type' },
        { key: 'account_number', label: 'Account Number' },
      ],
    }
  },

  mounted() {
    this.getBankAccounts()
  },
  methods: {
    getBankAccounts() {
      this.loader = true
      axios.get('office-summary').then(res => {
        const { data } = decryptData(res.data.payload)
        this.bankAccountsData = data.map(item => ({
          office_name: item.office_name,
          bank_accounts: item.bank_accounts,
        }))
      }).finally(() => {
        this.loader = false
      })
    },
    toggleIcon(index) {
      this.activeAccordion = this.activeAccordion === index ? null : index
    },
  },
}
</script>
<style>
.table-banks-accounts .table .thead-dark th{
    background-color: #1ea0fc;
    border-color: #1ea0fc;
    text-wrap: nowrap !important;
}
</style>
